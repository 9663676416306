const userlogindata={}
export const UserloginData=(state=userlogindata, action)=>{
    switch(action.type){
        case 'USER_LOGIN': 
        return{
            ...state,
            userlogindata:action.payload
        }   
        default:
            return state;    
    }

}


const orderData=[]

export const OrderAction =(state=orderData,action)=>{
    // console.log('State Order')
    switch(action.type){
        case 'CANCEL_ORDER': 
        return{
            ...state,
            orderData:action.payload
        } 
        case 'OPEN_DOOR': 
        return{
            ...state,
            orderData:action.payload
         }
        case 'PLACE_ORDER': 
        return{
            ...state,
            orderData:action.payload
        }     
        default:
            return state;    
    }

}

const fleetId=''
export const fleetIdreducer=(state=fleetId, action)=>{
    switch(action.type){
        case 'FETCH_FLEET_ID': 
        return{
            ...state,
            fleetId:action.payload
        }    
        default:
            return state;    
    }
}
const robotID=[]

export const robotidReducer=(state=robotID, action)=>{
    // console.log('RobotState', state,action.payload )
    switch(action.type){
        case 'GET_ROBOT_ID': 
        return{
            ...state,
            robotID:action.payload
        }    
        default:
            return state;    
    }
}
const fleetdata={}

export const fleetdataReducer=(state=fleetdata,action)=>{
    switch(action.type){
        case 'FETCH_FLEET_DATA': 
        return{
            ...state,
            fleetdata:action.payload
        }    
        default:
            return state;    
    }
}
