import { createStore,combineReducers } from 'redux'
import { OrderAction,fleetIdreducer,robotidReducer ,fleetdataReducer} from './reducers';

const mutipleReducers = combineReducers({
    fleetIdreducer,
    OrderAction,
    robotidReducer,
    fleetdataReducer
})

const store = createStore(mutipleReducers);

export default store