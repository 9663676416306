import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { getFleetList, getFleetListAdmin, getFleetListUser } from '../ApI/Index'
import { FleetData } from '../redux/actions'
import Header from './Header'



function SelectFleet() {

 const dispatch= useDispatch() 
 const [fleetlist, setfleetlist] =useState() 
 const navigate= useNavigate()
 
 const wholeData = useLocation()
 const token = localStorage.getItem('token')
  useEffect(()=>{
    setfleetlist(wholeData.state.name.fleet)
  },[])

 const handlefleetdiv=(fleetdata)=>{
  dispatch(FleetData(fleetdata))
  navigate(`/dashboard/${fleetdata.fleetId}`, {state:{name:fleetdata}})
 } 
  return (
    
    <>
    <Header data={"FleetPage"}/>
    {console.log('wholeData',wholeData)}
    <div className='container SelectFleetPage-wrapper-Main'>
           <h5 className='SelectPageMain-Heading'> Please select the fleet</h5>
        <div className="SelectFleetPage-content-wrapper-Main">    
          
          {fleetlist?.map((data)=>{
               return (
              <div className='border-blue-top SelectFleetPage-inner-li-div' onClick={()=>handlefleetdiv(data)}> 
                <h3 className='Dashboard-content-div-one-left-side-h3'> {data.fleetName}</h3>

                  {/* <div className='d-flexx-center '> 
                  <p className='SelectFleet-Page-p-tag'> Robot</p>
                  <h5 className='SelectFleet-Page-h5-tag'> 2</h5>
                </div>
                <div className='d-flexx-center '> 
                  <p className='SelectFleet-Page-p-tag'> Area</p>
                  <h5 className='SelectFleet-Page-h5-tag'> {data.location}</h5>
                </div>
                <div className='d-flexx-center '> 
                  <p className='SelectFleet-Page-p-tag'> Time</p>
                  <h5 className='SelectFleet-Page-h5-tag'> UTC-14</h5>
                </div>  */}
            </div> 
               )   
          })}
      </div>   
    </div>
    </>  
  )
}

export default SelectFleet