import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { OpenCabinAPI } from '../ApI/Index';
import { useDispatch } from 'react-redux';
import { Opendoor } from '../redux/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:'10px'
};

 function OpenCabin(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const dispatch = useDispatch()
  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpen(false);
  
  }
  const fleetid=localStorage.getItem('')
  const token= localStorage.getItem('token')
  const handleopencabin=()=>{
    OpenCabinAPI(props.id ,props.fleetid , token).then((res)=>{
          console.log('Open Cabin Response' , res)     
          props?.data?.data?.order?.map(item=>{
            if(item.orderId === props.id){
               item.orderStatus="Waiting for Drop-off"
            }
         })    
         dispatch(Opendoor(props.data.data.order)) 
         handleClose();   
    }).catch((err)=>{
        console.log('Open Cabin Error')
    })    
  }
  return (
    <>
      <Button className="btn-dashboard-blue-color" onClick={handleOpen}> Open Cabin</Button>
      <Modal
        open={open}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style} >
            <div className='Open-Cabin-Modal-Mainwrapper'>
            <img src="/assets/images/open-door.png"/>
               <h3> Open Cabin</h3>
               <p> Are you sure to Open the cabin</p>
               <div className="Modal-button-Main-wrapper">
               <Button className="Modal-button-Main-btn-two yes-blue-active" onClick={handleopencabin}> Yes</Button>
               <Button className="Modal-button-Main-btn-two" onClick={handleClose}> No</Button>
               </div>
           </div> 
        </Box>
      </Modal>
    </>
  );
}
export default React.memo(OpenCabin)