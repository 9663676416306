import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { orderlist } from '../Data/Orderlist';
import { useDispatch } from 'react-redux';
import { cancelOrder } from '../redux/actions';
import { cancelOrderAPI } from '../ApI/Index';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius:'10px'
};

 function CancelOrder(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);

  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpen(false)
 }
 
  const dispatch = useDispatch() 
  const token = localStorage.getItem('token')
  const handleOrderlistdata=()=>{
    cancelOrderAPI(props.id, props.fleetid ,token).then((res)=>{
        console.log('Cancel Order Response', res)
        props?.data?.data?.order?.map(item=>{
          if(item.orderId === props.id){
             item.orderStatus="order cancelled"
            //  const d = new Date();
            //  let time = d.getTime();
            //  item.updatedAt =time 
          }
       })
    console.log(' props?.data?.data?.order?', props?.data?.data?.order)
     dispatch(cancelOrder(props?.data?.data?.order))   
    }).catch((err)=>{
      console.log('Cancel Order Error', err)
    })
  }
  return (
    <>
      
      <Button className="btn-dashboard-red-color cancel-order" onClick={handleOpen}>Cancel</Button>
      <Modal
        open={open}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
        <Box sx={style} >
            <div className='Cancel-order-Modal-Mainwrapper'>
                <img src="/assets/images/cancel-order.png"/>
               <h3> Cancel Order</h3>
               <p> Are you sure to cancel the order</p>
               <div className="Modal-button-Main-wrapper">
               <Button className="Modal-button-Main-btn yes-red-active" onClick={()=>{
               handleOrderlistdata()
               handleClose()
            }}> Yes</Button>
               <Button className="Modal-button-Main-btn" onClick={handleClose}> No</Button>
               </div>
           </div> 
        </Box>
      </Modal>
    </>
  );
}
export default React.memo(CancelOrder)