export const cancelOrder=(data)=>{
    return{
        type: "CANCEL_ORDER",
        payload:data
    }
}
export const Opendoor=(data)=>{
    return{
        type: "OPEN_DOOR",
        payload:data
    }
}
export const placeorder=(data)=>{
    return{
        type: "PLACE_ORDER",
        payload:data
    }
}
export const userlogin=(data)=>{
    return{
        type: "USER_LOGIN",
        payload:data
    }
}
export const FleetIdAction=(data)=>{
    return{
        type: "FETCH_FLEET_ID",
        payload:data
    }
}
export const RobotIdAction=(data)=>{
    return{
        type: "GET_ROBOT_ID",
        payload:data
        
    }
}
export const FleetData=(data)=>{
    return{
        type:"FETCH_FLEET_DATA",
        payload:data
    }
}