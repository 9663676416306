import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';
import '../css/custom.css'
import AddNewOrder from '../SubComponents/AddNewOrder';
import { Link, useNavigate } from 'react-router-dom';
import { orderListFleetId } from '../ApI/Index';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

function Header(props) {
 const [orderList, setorderlist] =useState()
 const fleetID = localStorage.getItem('fleetID')
 useEffect(()=>{

 },[])
  const navigate= useNavigate()
  const handlelogoclick=()=>{
    localStorage.setItem('useremail',null)
    localStorage.setItem('password',null)
    localStorage.setItem('token', null)
    localStorage.setItem('fleetID', null)
    localStorage.setItem('Isloggedin', "LoginFalse")
    localStorage.setItem('mapid',null)
  }
  const handleLogoutButton=()=>{
    localStorage.setItem('useremail',null)
    localStorage.setItem('password',null)
    localStorage.setItem('token', null)
    localStorage.setItem('fleetID', null)
    localStorage.setItem('Isloggedin', "LoginFalse")
    localStorage.setItem('mapid',null)
  }
  const handlebackbutton=()=>{
    navigate(-1)
  }
  return (   
 <div className="Header-Main-wrapper">
  <div className='container '>
    <div className="row justify-content-between"> 
      <div className='col-md-4 text-left HeaderMain-logo-image-wrapper'> 
         <div style={{cursor:'pointer', marginTop:'7px'}} onClick={handlebackbutton}>
            <KeyboardBackspaceIcon style={{fontSize:"32px",marginRight: "8px"}}/>
         </div>
         <Link to="/" onClick={handlelogoclick}><img src="/assets/images/logo.png" className="HeaderMain-logo-image"/>
         </Link>
      </div>
      <div className='col-md-8 text-right d-flexx-center' style={{justifyContent:"end"}}> 
        {props.data==="FleetPage" ? null:<AddNewOrder checkaval={props.checkaval}/>}
       <Link to="/" onClick={handleLogoutButton} style={{width:'3%'}}> 
       <img src="/assets/images/power-off.png" className='Logout-image'/>
       </Link>
      </div>
    </div>
  </div>
  </div>
  )
}

export default Header