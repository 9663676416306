import axios from 'axios'

// const token='eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg'
 
export const login=(values)=>{
 return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}login/v1/login`,{
    emailId:values?.email,
    password:values?.password
  }).then((res)=>{
     return res
   })
}

export const getFleetListUser=(values, token)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}fleet/v1/getFleetListByUserEmail`,{
        emailId:values,
     }, {
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
          return res
      })
   }

   export const getFleetListAdmin=(values ,token)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}fleet/v1/getFleetListByAdminEmail`,{
        emailId:values,
     }, {
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
          return res
      })
   }   

export const cancelOrderAPI=(id, fleetid, token)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}order/v1/cancelOrder`,{
           orderId: id,
           fleetId:fleetid
 
    }, {
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
   }
   export const orderListFleetId=(values , page, size , token)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}order/v1/orderListByFleetIdPagination`, {
        fleetId:values,
        pageNo: page,
        size: size
    },{
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
   }
   export const robotListfleetId=(values,token)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}robot/v1/getRobotStatusByFleetId`,{
            fleetId:values    
     },{
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
   }
   export const AddnewOrder=(values, fleetId,useremail , token)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}order/v1/placeOrder`, {
        orderId:values.orderID ,
        fleetId:fleetId,
        customerName:"Admin",
        customerMobileNumber:"+917697813828",
        customerEmail:useremail,
        orderValue:"999",
        orderDetails:"FOOD",
        pickupId: values.pickuplocation,
        dropId: values.deliverylocation,
        qrcodeDrop: "AS223",
        timeout: "180",
        timeoutBeh: "1000",
        deadline:"1000"
    },{
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
   }
   export const OpenCabinAPI=(orderid, fleetid, token)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}interrupt/v1/openDoorByOrderId`, {
        orderId: orderid,
        fleetId:fleetid
    },{
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
   }
   
   export const MapDetails=(fleetid , token)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}map/v1/getMapDetailByfleetId`, {
        fleetId:fleetid
    },{
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
   }
   export const getLocationList=(fleetid, token)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}location/v1/locationListByFleetId`, {
        fleetId:fleetid
    },{
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
   }


 export const getMapLocationList =(fleetid , token)=>{
    
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}location/v1/locationListByFleetId`, {
        fleetId:fleetid
    },{
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
 }  

 export const getrobotStatus=  (robotid , token)=>{
    return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}robot/v1/getRobotStatus`, {
        robotId:robotid
    },{
          headers:{
              'Authorization':`Bearer ${token}`,
              'Content-Type': 'application/json', 
          }
      }).then((res)=>{
        return res
    })
 }

//  export const checkfleetavailble=(fleetId, token)=>{
//     return axios.post(`${process.env.REACT_APP_APIOTTONOMY_URL}fleet/v1/checkFleetAvailability`, {
//         fleetId:fleetId
//     },{
//           headers:{
//               'Authorization':`Bearer ${token}`,
//               'Content-Type': 'application/json', 
//           }
//       }).then((res)=>{
//         return res
//     })
//  }