import logo from './logo.svg';
import './App.css';
import Dashboard from './components/Dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/custom.css'
import Login from './components/Login';
import { Link, Route, Routes,Redirect } from 'react-router-dom';
import SelectFleet from './components/SelectFleet';
function App() {
  
  const isLogin = localStorage.getItem('token')
  
  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path='dashboard/:fleetId' element={<Dashboard/>} /> 
          <Route path='selectfleet/:email' element={<SelectFleet/>} />
       </Routes>
  
    </div>
  );
}

export default App;
